import { Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import NewEmployeeForm from "../pages/admin/NewEmployeeForm";
import DeleteItem from "../components/ui/DeleteItem";
import constants from "../constants/constants";

export default function EmployeesTable({ employees, setEmployees }: any) {
  const { t } = useTranslation();

  const rows = employees?.map((element: any, index: number) => (
    <Table.Tr key={element.phoneNumber}>
      <Table.Td>{index + 1}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.idEventer}</Table.Td>
      <Table.Td>{element.idGoOut}</Table.Td>
      <Table.Td>{element.idZygo}</Table.Td>
      <Table.Td>{element.phoneNumber}</Table.Td>
      <Table.Td>{element.id}</Table.Td>
      <Table.Td>{element.personalLink}</Table.Td>
      <Table.Td>
        <DeleteItem
          id={element.id}
          setItems={setEmployees}
          deleteEndpoint={`${constants.API_BASE_URL}${constants.DELETE_EMPLOYEE_ENDPOINT}`}
        />
        
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Table stickyHeader stickyHeaderOffset={60} striped withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t("columnNumber")}</Table.Th>
            <Table.Th>{t("columnEmployeeName")}</Table.Th>
            <Table.Th>{t("columnEmployeeIdEventer")}</Table.Th>
            <Table.Th>{t("columnEmployeeIdGoOut")}</Table.Th>
            <Table.Th>{t("columnEmployeeIdZygo")}</Table.Th>
            <Table.Th>{t("columnEmployeePhone")}</Table.Th>
            <Table.Th>מזהה אישי</Table.Th>
            <Table.Th>לינק אישי</Table.Th>
            <Table.Th>{t("other")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      <NewEmployeeForm setEmployees={setEmployees} />
    </>
  );
}
