import { useParams } from "react-router-dom";
import constants from "../constants/constants";

export const usePersonalizedUrl = (
  eventUrl: string,
  employees: models.EmployeeProps[]
) => {
  const { id } = useParams();
  const employee = employees.find((employee) => employee.id === id);

  const getEmployeeIdForUrl = (url: string) => {
    if (url.includes(constants.EVENTER)) {
      if (!employee || employee.idEventer === "") {
        console.log("work")
        const eventIdMatch = url.match(/\/event\/([^/]+)/);
        console.log(eventIdMatch);
        if (eventIdMatch && eventIdMatch[1]) {
          return `/${eventIdMatch[1]}`;
        }
        return "";
      }
      return `/${employee.idEventer}`;
    } else if (url.includes(constants.GO_OUT)) {
      if (!employee || employee.idGoOut === "") {
        return "";
      }
      return employee ? `?ref=${employee.idGoOut}` : "";
    } else if (url.includes(constants.ZYGO)) {
      return employee ? `/${employee.idZygo}` : "";
    }
    return "";
  };

  const employeeId = getEmployeeIdForUrl(eventUrl);

  const baseUrl =
  eventUrl.includes(constants.EVENTER) && (!employee || employee.idEventer === "")
  ? eventUrl.replace(/\/event\/[^/]+/, "")
      : eventUrl;

  return `${baseUrl}${employeeId}`;
};
