import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  he: {
    translation: {
      addEventsTitle: "הוספת אירוע",
      addEventLabel: "אירוע חדש",
      addEventPlaceholder: "נא הכנס את קישור האירוע כדי להוסיף אותו למערכת",
      addEventButton: "הוסף אירוע",
      loading: "בטעינה....",
      logOutButton: "התנתק",
      loginPageTitle: "התחברות",
      emailLoginLabel: "אימייל",
      passwordLoginLabel: "סיסמא",
      emailLoginPlaceholder: "נא הכנס אימייל",
      passwordLoginPlaceholder: "נא הכנס סיסמא",
      loginButton: "כניסה",
      addNewEmployee: "הוסף יחצן",
      nameNewEmployeeLabel: "שם מלא",
      nameNewEmployeePlaceholder: "נא הכנס שם מלא",
      idNewEmployeeEventerLabel: "מספר מזהה eventer",
      idNewEmployeeGoOutLabel: "מספר מזהה go out",
      idNewEmployeeZygoLabel: "מספר מזהה zygo",
      idNewEmployeePlaceholder: "נא הכנס מספר מזהה",
      phoneNewEmployeeLabel: "מספר טלפון",
      PhoneNewEmployeePlaceholder: "נא הכנס מספר טלפון",
      eventAlreadyExistTitle: "האירוע כבר קיים!",
      eventAlreadyExist: "האירוע שניסית להוסיף כבר קיים במערכת",
      eventAddedTitle: "האירוע נוסף בהצלחה!",
      eventAddedMessage: "האירוע שסרקת נוסף לאתר ולטבלה למטה",
      problemWithScraperTitle: "תקלה",
      problemWithScraperMessage:
        "המערכת לא הצליחה לסרוק את האתר, נא בדוק את כתובת האתר שרשמת ונסה שוב",
      columnNumber: "מס'",
      columnEvent: "שם אירוע",
      columnEventDate: "תאריך",
      columnEventLocation: "מיקום",
      columnEmployeeName: "שם יחצן",
      columnEmployeeIdEventer: "מזהה אוונטר",
      columnEmployeeIdGoOut: "מזהה גו-אאוט",
      columnEmployeeIdZygo: "מזהה זיגו",
      columnEmployeePhone: "טלפון",
      homeButton: "דף הבית",
      accessibilityStatement: "הצהרת נגישות",
      contactUs: "רוצים לעבוד איתנו?",
      PRIVACY_POLICY_URL: "מדיניות פרטיות",
      searchEvent: "חיפוש אירוע",
      eventsTab: "אירועים",
      employeesTab: "יחצנים",
      refreshEventsButton: "שמור שינויים",
      carouselTitle: "קרוסלה",
      addcarousel: "הוסף אירוע לקרוסלה",
      saveCarousel: "שמור קרוסלה",
      facebookButton: "הצטרפו אלינו בפייסבוק",
      instagramButton: "הצטרפו אלינו באינסטגרם",
      whatsappButton: "הצטרפו אלינו בוואטסאפ",
      other: "אחר",
      loadMoreEvents: "טען עוד אירועים.. 🎉",
      search: "חיפוש",
      searchPlaceholder: "חפשו אומן, מסיבה או לוקיישן...",
      locationInTable: "מיקום",
      saveTableChanges: "שמור שינויים בטבלה",
      addCategoriesTitle: "קטגוריות",
      WorkWithUs: "רוצים לעבוד איתנו?",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "he",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
