import { Title, Text } from "@mantine/core";
import "../../css/home/AboutUs.css"; // Add a dedicated CSS file for AboutUs

export default function AboutUs() {
  return (
    <div className="about-us-container">
      <div className="about-us-section">
        <Title order={2} className="about-us-title">
          הלילה מתחיל בחלומות גדולים
        </Title>
        <Text className="about-us-text">
          כשאתם יוצאים למסיבה, יש תמיד את ההתרגשות הזו באוויר. אתם מתארגנים,
          שולחים הודעות לחברים, מתכננים את הסטייל המושלם ויוצאים למסע של לילה
          בלתי נשכח. אתם מדמיינים את הרחבה מלאה, את המוזיקה שסוחפת אתכם, ואת
          האנרגיות שמתפוצצות באוויר. זה הלילה שלכם, והכול צריך להיות מושלם –
          מהמשקה הראשון ועד הרגע שהשמש זורחת.
        </Text>
      </div>

      <div className="about-us-section">
        <Title order={2} className="about-us-title">
          אבל המציאות לא תמיד זוהרת
        </Title>
        <Text className="about-us-text">
          מה קורה כשבפועל דברים לא מתקדמים כמתוכנן? אתם מגיעים למקום והנה תור
          אינסופי, הרשימות לא מסתדרות, והאווירה מתחילה לנסוק לכיוון הלא נכון.
          במקום לרקוד, אתם עומדים בחוץ עם עוד מאות אנשים, מנסים להבין למה שילמתם
          על משהו שלא מתקיים כמו שהובטח. מסיבות שנקטעות, הפקות שלא עונות על
          הציפיות, ואנשי יח"צ שמבטיחים עולם ומלואו – אבל לא מספקים את הסחורה. כל
          החוויה מתמוססת עוד לפני שהתחילה
        </Text>
      </div>

      <div className="about-us-section">
        <Title order={2} className="about-us-title">
          ENTER – כי אנחנו יודעים איך עושים את זה נכון
        </Title>
        <Text className="about-us-text">
          כאן נכנס ENTER, המקום שבו הלילה שלכם מתחיל בצורה חלקה ונגמר בחיוך.
          אנחנו דואגים לסנן עבורכם את כל ההפקות הלא מבוססות, כדי שתדעו מראש
          בדיוק לאן אתם הולכים ומה מחכה לכם. כל אירוע שעובר דרכנו נבדק בקפידה,
          עם המלצות מותאמות אישית לצרכים שלכם – בין אם אתם מחפשים מסיבת טכנו
          עמוקה, מסע בפסטיבל טראנס, או ערב קליל של מיינסטרים עם חברים. עם ENTER,
          אין יותר הפתעות לא נעימות, רק לילות של הנאה רצופה, כשאנחנו דואגים לכל
          הפרטים הקטנים – כדי שאתם תתרכזו בלעשות כיף.
        </Text>
      </div>
      <div className="about-us-section">
        <Title order={2} className="about-us-title">
          להיות חלק מקהילה שיודעת מה היא רוצה{" "}
        </Title>
        <Text className="about-us-text">
          ב-ENTER אנחנו לא רק מספקים כרטיסים לאירועים – אנחנו יוצרים קהילה של
          אנשים שיודעים מה הם מחפשים בבילוי הלילי שלהם. אנחנו מבינים שכשאתם
          יוצאים, אתם מחפשים לא רק מוזיקה טובה, אלא גם קהל איכותי, חוויה חלקה,
          ואנרגיה חיובית שמחברת את כולם. לכן, אנחנו לא רק שומרים על הסטנדרטים
          הגבוהים ביותר בבחירת ההפקות, אלא גם מקפידים על שיח מכבד, תמיכה אישית,
          ותחושת שייכות לכל מי שבוחר בנו. כשאתם איתנו, אתם לא רק הולכים למסיבה –
          אתם חלק ממשהו גדול יותר, מקום שבו לילה טוב הוא לא רק הבטחה, אלא מציאות
        </Text>
      </div>
    </div>
  );
}
