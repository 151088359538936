import { useState, useEffect } from "react";
import useApi from "./useApi";
import constants from "../constants/constants";

export const useDataFetcher = () => {
  const [events, setEvents] = useState<models.EventProps[]>([]);
  const [employees, setEmployees] = useState<models.EmployeeProps[]>([]);
  const [utils, setUtils] = useState<any[]>([]);

  const {
    data: db,
    callApi: callApiDb,
    loading: loadingDb,
  } = useApi(`${constants.API_BASE_URL}${constants.FIREBASE_DB_ENDPOINT}`);

  const { data: dataUuid, callApi: callApiUuid } = useApi(
    `${constants.API_BASE_URL}${constants.GET_UUID_ENDPOINT}`
  );

  const storedUtils = localStorage.getItem(constants.UTILS_COLLECTION);
  const parsedUtils = storedUtils ? JSON.parse(storedUtils) : null;

  const storedEvents = localStorage.getItem(constants.EVENTS_COLLECTION);
  const parsedEvents = storedEvents ? JSON.parse(storedEvents) : null;

  const storedEmployees = localStorage.getItem(constants.EMPLOYEES_COLLECTION);
  const parsedEmployees = storedEmployees ? JSON.parse(storedEmployees) : null;

  const saveToLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  useEffect(() => {
    if (
      !parsedEvents ||
      !parsedEmployees ||
      !parsedUtils ||
      (Array.isArray(parsedUtils) && parsedUtils.length === 0)
    ) {
      console.log("Fetching from Firebase");
      callApiDb("POST");
      return;
    }

    callApiUuid("POST", { parsedUtils });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("Checking UUIDs");
    if (dataUuid) {
      if (!dataUuid.employees || !dataUuid.events) {
        if (parsedEvents) setEvents(parsedEvents);
        if (parsedEmployees) setEmployees(parsedEmployees);
        if (parsedUtils) setUtils(parsedUtils);
      }
      if (dataUuid.employees) {
        callApiDb("POST", { collection: constants.EMPLOYEES_COLLECTION });
      }
      if (dataUuid.events) {
        callApiDb("POST", { collection: constants.EVENTS_COLLECTION });
      }
      if (dataUuid.categories) {
        callApiDb("POST", { collection: constants.UTILS_COLLECTION });
      }
    }
    // eslint-disable-next-line
  }, [dataUuid]);

  useEffect(() => {
    if (db) {
      if (db.events) {
        let orderedEvents = [];

        if (db.utils && db.utils[1] && db.utils[1].order) {
          const eventsMap = new Map();
          db.events.forEach((event: { id: string }) => {
            eventsMap.set(event.id, event);
          });

          orderedEvents = db.utils[1].order
            .map((id: string) => eventsMap.get(id))
            .filter(Boolean);
        }

        if (orderedEvents.length === 0) {
          console.warn("Ordered events are empty, using events as they are from Firebase");
          orderedEvents = db.events;
        }

        saveToLocalStorage(constants.EVENTS_COLLECTION, orderedEvents);
        setEvents(orderedEvents);
      }

      if (db.employees) {
        saveToLocalStorage(constants.EMPLOYEES_COLLECTION, db.employees);
        setEmployees(db.employees);
      }

      if (db.utils) {
        saveToLocalStorage(constants.UTILS_COLLECTION, db.utils);
        setUtils(db.utils);
      }
    }
  }, [db]);

  return {
    setEvents,
    events,
    employees,
    setEmployees,
    utils,
    loading: loadingDb,
    callApiDb,
  };
};
