import "../css/Login.css";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import { Button, Title } from "@mantine/core";
import { TextInput, PasswordInput } from "@mantine/core";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface FormValues {
  email: string;
  password: string;
}

export default function Login() {
  const loginForm = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const { loginUser, user } = useAuth();
  const methods = useForm<FormValues>();
  const { register, handleSubmit } = methods;
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      navigate("/admin");
    }
  }, [user, navigate]);

  const onSubmit = async (data: FormValues) => {
    try {
      await loginUser(data.email, data.password);
    } catch (error: any) {
      console.error("Login failed:", error.message);
    }
  };

  return (
    <div className="login-container">
      <FormProvider {...methods}>
        <form
          ref={loginForm}
          onSubmit={handleSubmit(onSubmit)}
          className="login-form-container"
        >
          <Title>{t("loginPageTitle")}</Title>
          <TextInput
            label={t("emailLoginLabel")}
            placeholder={t("emailLoginPlaceholder")}
            required
            mt="md"
            {...register("email", { required: true })}
          />
          <PasswordInput
            label={t("passwordLoginLabel")}
            placeholder={t("passwordLoginPlaceholder")}
            required
            mt="md"
            {...register("password", { required: true })}
          />
          <div>
            <Button style={{ marginTop: "10px" }} type="submit">
              {t("loginButton")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
