import { Tabs } from "@mantine/core";

interface TabProps {
  value: string;
  leftSection: any;
  tabName: string;
}

export default function Tab({ value, leftSection, tabName }: TabProps) {
  return (
    <div>
      <Tabs.Tab value={value} leftSection={leftSection}>
        {tabName}
      </Tabs.Tab>
    </div>
  );
}
