import { useRef, useState } from "react";
import { TextInput, Modal, Button } from "@mantine/core";
import { useForm, FormProvider } from "react-hook-form";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useTranslation } from "react-i18next";
import constants from "../../constants/constants";
import { v4 as uuidv4 } from "uuid";
import { ClipLoader } from "react-spinners";

interface FormValues {
  name: string;
  idEventer: string;
  phoneNumber: string;
  idGoOut: string;
  idZygo: string;
}

interface NewEmployeeFormProps {
  setEmployees: React.Dispatch<React.SetStateAction<any[]>>;
}

function NewEmployeeForm({ setEmployees }: NewEmployeeFormProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const newEmployeeForm = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation();

  const methods = useForm<FormValues>();
  const { register, handleSubmit, reset } = methods;

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    try {
      const newEmployee = await axios.post(
        `${constants.API_BASE_URL}${constants.ADD_EMPLOYEE_ENDPOINT}`,
        {
          data,
          uuid: uuidv4(),
        }
      );
      console.log("Form submitted successfully!");
      setEmployees((prevEmployees) => [...prevEmployees, newEmployee.data]);
      reset();
      close();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={t("addNewEmployee")}
        size="auto"
        centered
      >
        <FormProvider {...methods}>
          <form ref={newEmployeeForm} onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label={t("nameNewEmployeeLabel")}
              placeholder={t("nameNewEmployeePlaceholder")}
              required
              mt="md"
              {...register("name", { required: true })}
            />
            <TextInput
              label={t("idNewEmployeeEventerLabel")}
              placeholder={t("idNewEmployeePlaceholder")}
              mt="md"
              {...register("idEventer")}
            />
            <TextInput
              label={t("idNewEmployeeGoOutLabel")}
              placeholder={t("idNewEmployeePlaceholder")}
              mt="md"
              {...register("idGoOut")}
            />
            <TextInput
              label={t("idNewEmployeeZygoLabel")}
              placeholder={t("idNewEmployeePlaceholder")}
              mt="md"
              {...register("idZygo")}
            />
            <TextInput
              label={t("phoneNewEmployeeLabel")}
              placeholder={t("PhoneNewEmployeePlaceholder")}
              required
              mt="md"
              {...register("phoneNumber", { required: true })}
            />
            <Button
              color="black"
              style={{ marginTop: "10px" }}
              type="submit"
              disabled={loading}
            >
              {t("addNewEmployee")}{" "}
            </Button>
            <div style={{ margin: "10px" }}>
              {loading && <ClipLoader size={30} />}
            </div>
          </form>
        </FormProvider>
      </Modal>
      <div className="save-button-container">
        <Button color="black" onClick={open} type="submit">
          {t("addNewEmployee")}
        </Button>
      </div>
    </>
  );
}
export default NewEmployeeForm;
