import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { auth } from "./firebase";
import {
  User,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import blackLogo from "../assets/logos/blackLogo.png";
import { ClipLoader } from "react-spinners";

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loginUser: (
    email: string | undefined,
    password: string | undefined
  ) => Promise<void>;
  logoutUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState<Boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    checkUserStatus();
  }, []);

  const loginUser = async (
    email: string | undefined,
    password: string | undefined
  ) => {
    setLoading(true);
    try {
      if (!email || !password) return;
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
    } catch (error: any) {
      console.error(error);
    }
    setLoading(false);
  };

  const logoutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error: any) {
      console.error(error);
    }
  };

  const checkUserStatus = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const user = auth.currentUser;
      setUser(user);
    } catch (error: any) {
      console.log(error);
    }
    setLoading(false);
  };

  const contextData = {
    user,
    setUser,
    loginUser,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={80} />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
