import { useParams } from "react-router-dom";
import "../../css/event/EventPage.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useDataFetcher } from "../../hooks/useDataFetcher";
import parse from "html-react-parser";
import blackLogo from "../../assets/logos/blackLogo.png";
import StickyFooter from "./StickyFooter";
import WhatsAppButton from "../../components/socialMedia/WhatsAppButton";
import FacebookButton from "../../components/socialMedia/FacebookButton";
import InstagramButton from "../../components/socialMedia/InstagramButton";
import DiscountBanner from "./../event/DiscountBanner";
import OrderTicketsButton from "./OrderTicketsButton";
import EventCubeList from "../../components/EventCubeList";
import { Title } from "@mantine/core";
import { ClipLoader } from "react-spinners";

function EventPage() {
  const { eventTitle } = useParams();
  const { t } = useTranslation();

  const { events, employees, loading } = useDataFetcher();

  const data = events.find((event) => event.eventTitle === eventTitle);
  console.log(data);
  const parsedDescription = data?.eventDescription
    ? parse(data.eventDescription)
    : "";

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={50} />
        </div>
      ) : (
        <>
          <h1 className="event-title-top">{data?.eventTitle}</h1>
          <div className="event-page-container">
            <div className="event-page-card">
              <div className="event-page-image-container">
                <img
                  src={data?.eventImage}
                  alt={data?.eventTitle}
                  className="event-page-image"
                />
                <WhatsAppButton />
                <InstagramButton />
                <FacebookButton />
              </div>

              <div className="event-details">
                <div className="event-info">
                  <p className="event-date">תאריך: {data?.eventDate}</p>
                  <p className="event-location">מיקום: {data?.eventLocation}</p>
                </div>
                {data?.eventUrl && (
                  <OrderTicketsButton
                    eventUrl={data.eventUrl}
                    employees={employees}
                  />
                )}
                {data?.eventImage && (
                  <DiscountBanner imageUrl={data.eventImage} />
                )}
                <div className="event-description">{parsedDescription}</div>
                {data?.eventUrl && (
                  <OrderTicketsButton
                    eventUrl={data.eventUrl}
                    employees={employees}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="additional-events-container">
            <Title order={2} className="additional-events-title">
              עוד אירועים שלנו!
            </Title>
          </div>
          <EventCubeList events={events} loading={loading} />
        </>
      )}
      {data && <StickyFooter event={data} employees={employees} />}
      <Footer />
    </>
  );
}
export default EventPage;
