import { useState, useEffect } from "react";
import { Button, Divider } from "@mantine/core";
import { useAuth } from "../../utils/AuthContext";
import EventsTable from "../../tables/EventsTable";
import AdminTabs from "../../tables/AdminTabs";
import EmployeesTable from "../../tables/EmployeesTable";
import { useTranslation } from "react-i18next";
import constants from "../../constants/constants";
import CarouselEventManager from "./CarouselEventManager";
import { useDataFetcher } from "../../hooks/useDataFetcher";
import blackLogo from "../../assets/logos/blackLogo.png";
import AddEvent from "./AddEvent";
import CustomCategories from "./CustomCategories";
import { ClipLoader } from "react-spinners";
import "../../css/admin/Admin.css";

function Admin() {
  const { logoutUser } = useAuth();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<string | null>(constants.EVENTS_TAB);
  const [customCategories, setCustomCategories] = useState<any[]>([]);

  const {
    events,
    setEvents,
    employees,
    setEmployees,
    utils,
    callApiDb,
    loading: loadingDb,
  } = useDataFetcher();

  useEffect(() => {
    if (utils && utils.length > 0 && utils[0]?.categories) {
      setCustomCategories(utils[0].categories);
    }
  }, [utils]);

  return (
    <div className="admin-container">
      {loadingDb ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={50} />
        </div>
      ) : (
        <div>
          {/* Top Section: AddEvent, CustomCategories, CarouselEventManager */}
          <div className="top-section">
            <div className="component-wrapper">
              <AddEvent
                callApiDb={callApiDb}
                loadingDb={loadingDb}
                customCategories={customCategories}
                setEvents={setEvents}
                events={events}
              />
            </div>
            <div className="component-wrapper">
              <CustomCategories
                customCategories={customCategories}
                setCustomCategories={setCustomCategories}
              />
            </div>
            <div className="component-wrapper">
              <CarouselEventManager eventsList={events} />
            </div>
          </div>

          <Divider size="xs" color="black" style={{ marginTop: "20px" }} />

          <div className="table-section">
            <AdminTabs tabs={tabs} setTabs={setTabs} />
            {tabs === constants.EVENTS_TAB ? (
              <EventsTable
                events={events}
                setEvents={setEvents}
                employees={employees}
              />
            ) : (
              <EmployeesTable
                employees={employees}
                setEmployees={setEmployees}
              />
            )}
          </div>

          <div className="logout-button">
            <Button color="black" onClick={() => logoutUser()}>
              {t("logOutButton")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;
