import { useState } from "react";
import { Table, Select, Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import DeleteItem from "../components/ui/DeleteItem";
import constants from "../constants/constants";
import useApi from "../hooks/useApi";
import { v4 as uuidv4 } from "uuid";
import EventTags from "../components/EventTags";
import { ClipLoader } from "react-spinners";
import "../css/tables/Table.css";
// import CustomLinkEvent from "../components/CustomLinkEvent";

interface EventsTableProps {
  events: models.EventProps[];
  setEvents: React.Dispatch<React.SetStateAction<models.EventProps[]>>;
  employees: models.EmployeeProps[];
}

export default function EventsTable({
  events,
  setEvents,
  employees,
}: EventsTableProps) {
  const { t } = useTranslation();

  const [orderChanged, setOrderChanged] = useState(false);

  const { callApi: callApiEvent, loading } = useApi(
    `${constants.API_BASE_URL}${constants.ORDER_EVENTS_ENDPOINT}`
  );

  const updatedEvents = async () => {
    const newEventsOrder = events.map((event: models.EventProps) => event.id);

    console.log(newEventsOrder);

    callApiEvent("POST", { newEventsOrder, uuid: uuidv4() });

    setOrderChanged(false);
  };

  const numberOfEvents = Array.from({ length: events.length }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const handleTableChange = (
    selectedIndex: number,
    currentEventIndex: number
  ) => {
    const updatedEvents = [...events];

    const [movedEvent] = updatedEvents.splice(currentEventIndex, 1);
    updatedEvents.splice(selectedIndex - 1, 0, movedEvent);

    setEvents(updatedEvents);

    setOrderChanged(true);
  };

  const rows = events?.map((element: any, index: number) => (
    <Table.Tr key={element.eventUrl}>
      <Table.Td>
        <Select
          data={numberOfEvents}
          value={(index + 1).toString()}
          rightSection={null}
          rightSectionWidth={0}
          style={{
            width: 70,
          }}
          onChange={(value) => handleTableChange(Number(value), index)}
        />
      </Table.Td>
      <Table.Td>{element.eventTitle}</Table.Td>
      <Table.Td>{element.eventDate}</Table.Td>
      <Table.Td>{element.eventLocation}</Table.Td>
      <Table.Td
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="icons-container">
          <DeleteItem
            id={element.id}
            setItems={setEvents}
            deleteEndpoint={`${constants.API_BASE_URL}${constants.DELETE_EVENT_ENDPOINT}`}
          />
          <EventTags event={element} events={events} setEvents={setEvents} />
          {/* <CustomLinkEvent event={element} employees={employees} /> */}
        </div>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <div className="events-table-container">
        <Table striped withColumnBorders className="events-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t("columnNumber")}</Table.Th>
              <Table.Th>{t("columnEvent")}</Table.Th>
              <Table.Th>{t("columnEventDate")}</Table.Th>
              <Table.Th>{t("columnEventLocation")}</Table.Th>
              <Table.Th>{t("other")}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>
      <div className="save-button-container">
        <Button
          color="black"
          onClick={updatedEvents}
          disabled={!orderChanged || loading}
        >
          {t("saveTableChanges")}
        </Button>
        {loading && <ClipLoader size={30} />}
      </div>
    </>
  );
}
