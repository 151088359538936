import { Link } from "react-router-dom";
import "../../css/event/OrderTicketsButton.css";
import { IoTicketSharp } from "react-icons/io5";
import { usePersonalizedUrl } from "../../hooks/usePersonalizedEventUrl";

interface OrderTicketsButtonProps {
  eventUrl: string;
  employees: models.EmployeeProps[];
}

export default function OrderTicketsButton({
  eventUrl,
  employees,
}: OrderTicketsButtonProps) {
  const finallUrl = usePersonalizedUrl(eventUrl, employees);

  return (
    <div className="purchase-button-container">
      <Link to={finallUrl}>
        <button className="purchase-button">
          <IoTicketSharp />
          להזמנת כרטיסים
        </button>
      </Link>
    </div>
  );
}
