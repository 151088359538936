import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Carousel from "./Carousel";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import EventCubeList from "../../components/EventCubeList";
import { useDataFetcher } from "../../hooks/useDataFetcher";
import EventCategorySelector from "./EventCategorySelector";
import blackLogo from "../../assets/logos/blackLogo.png";
import EventSearchBar from "./EventSearchBar";
import ReactWhatsapp from "react-whatsapp";
import { FaWhatsapp } from "react-icons/fa";
import "../../css/home/WhatsappFloatingButton.css";
import AboutUs from "./AboutUs";
import SocialMediaSection from "../SocialMediaSection";
import constants from "../../constants/constants";
import { ClipLoader } from "react-spinners";
import PopUpAd from "./PopUpAd";

function Home() {
  const { t } = useTranslation();
  const { events, loading, utils } = useDataFetcher();

  const [customCategories, setCustomCategories] = useState<string[]>([]);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    if (utils && utils.length > 0 && utils[0].categories) {
      setCustomCategories(utils[0].categories);
    }
  }, [utils]);

  useEffect(() => {
    const hasShownPopup = sessionStorage.getItem("hasShownPopup");

    if (!hasShownPopup) {
      const timer = setTimeout(() => {
        setShowPopUp(true);
        sessionStorage.setItem("hasShownPopup", "true");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const [selectedFilters, setSelectedFilters] = useState<{
    time: string | null;
    event: string | null;
    area: string | null;
  }>({
    time: null,
    event: null,
    area: null,
  });

  const filterEvents = (events: models.EventProps[]) => {
    return events.filter((event) => {
      const eventTags = event.tags || [];

      const matchTime = selectedFilters.time
        ? eventTags.includes(selectedFilters.time)
        : true;

      const matchEvent = selectedFilters.event
        ? eventTags.includes(selectedFilters.event)
        : true;

      const matchArea = selectedFilters.area
        ? eventTags.includes(selectedFilters.area)
        : true;

      return matchEvent && matchArea && matchTime;
    });
  };

  const filteredEvents = filterEvents(events);

  return (
    <div>
      {!events ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={100} />
        </div>
      ) : (
        <>
          <Navbar />
          <Carousel events={events} loading={loading} />
          <EventCategorySelector
            onFilterChange={setSelectedFilters}
            customTimeCategories={customCategories}
          />
          <EventCubeList events={filteredEvents} loading={loading} />
          <EventSearchBar />
          <AboutUs />
          <SocialMediaSection />
          <Footer />
          <ReactWhatsapp
            element="button"
            number={constants.SHAHAR_NUMBER}
            message="היי הגעתי מאתר ENTER אשמח לקבל קוד הטבה לאירוע .."
            className="whatsapp-button"
          >
            <FaWhatsapp size="38px" />
          </ReactWhatsapp>
          <PopUpAd onClose={() => setShowPopUp(false)} opened={showPopUp} />
        </>
      )}
    </div>
  );
}

export default Home;
