import { Tabs, rem } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
// import { IconMessageCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Tab from "../components/ui/Tab";
import constants from "../constants/constants";


interface TabsTableProps {
  tabs: string | null;
  setTabs: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function AdminTabs({ tabs, setTabs }: TabsTableProps) {
  const { t } = useTranslation();

  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <Tabs
      value={tabs}
      onChange={setTabs}
      color="gray"
      variant="outline"
      radius="lg"
      defaultValue="gallery"
    >
      <Tabs.List>
        <Tab
          value={constants.EVENTS_TAB}
          leftSection={<IconPhoto style={iconStyle} />}
          tabName={t("eventsTab")}
        />
        {/* <Tab
          value={constants.EMPLOYEES_TAB}
          leftSection={<IconMessageCircle style={iconStyle} />}
          tabName={t("employeesTab")}
        /> */}
      </Tabs.List>
    </Tabs>
  );
}
