// Navbar
const HOME_URL = "/";
const SEARCH_URL = "/search";
const WORK_WITH_US_URL = "/work-with-us";

// Footer
const ACCESSIBILITY_STATEMENT_URL = "/accessibility-statement";
const CONTACT_US_URL = "/contact-us";
const PRIVACY_POLICY_URL = "/privacy-policy";

// Tabs
const EVENTS_TAB = "events";
const EMPLOYEES_TAB = "employees";

// API Base URL
// const API_BASE_URL = "http://localhost:3001/";
const API_BASE_URL = "https://enter-tickets-backend.publicvm.com/";

// API Endpoints
const FIND_EVENT_ENDPOINT = "event/find";
const DELETE_EVENT_ENDPOINT = "event/delete";
const ORDER_EVENTS_ENDPOINT = "event/order";
const FIREBASE_DB_ENDPOINT = "firebase-db";
const UPDATE_CAROUSEL_EVENTS_ENDPOINT = "carousel-events/update";
const GET_UUID_ENDPOINT = "uuid/check";
const ADD_EMPLOYEE_ENDPOINT = "employee/add";
const DELETE_EMPLOYEE_ENDPOINT = "employee/delete";
const ADD_TAG_ENDPOINT = "event/add-tag";
const DELETE_TAG_ENDPOINT = "event/remove-tag";
const ADD_CUSTOM_CATEGORY_ENDPOINT = "event/add-custom-category";
const DELETE_CUSTOM_CATEGORY_ENDPOINT = "event/delete-custom-category";
const FORM_SUBMISSION_ENDPOINT = "form-submission";

// Collections (Database)
const EVENTS_COLLECTION = "events";
const EMPLOYEES_COLLECTION = "employees";
const UTILS_COLLECTION = "utils";

// HTTP Methods
const HTTP_POST = "POST";
const HTTP_GET = "GET";

// External Links
const FACEBOOK_URL =
  "https://www.facebook.com/profile.php?id=61566183797017&mibextid=LQQJ4d";
const INSTAGRAM_URL =
  "https://www.instagram.com/enter.tickets?igsh=Znc5YzA0dHlpYjdi";
const WHATSAPP_GROUP_URL = "https://chat.whatsapp.com/DbX55SP6NsqCRPI9Ekrd5a";
const SHAHAR_NUMBER = "+972549100301";

// Event Status
const EVENT_EXISTS = "exist";

const EVENTER = "eventer";
const GO_OUT = "go-out";
const ZYGO = "zygo";

const constants = {
  // Navbar
  HOME_URL,
  SEARCH_URL,
  WORK_WITH_US_URL,

  // Footer
  ACCESSIBILITY_STATEMENT_URL,
  CONTACT_US_URL,
  PRIVACY_POLICY_URL,

  // Tabs
  EVENTS_TAB,
  EMPLOYEES_TAB,

  // API Base URL
  API_BASE_URL,

  // API Endpoints
  FIND_EVENT_ENDPOINT,
  DELETE_EVENT_ENDPOINT,
  ORDER_EVENTS_ENDPOINT,
  FIREBASE_DB_ENDPOINT,
  UPDATE_CAROUSEL_EVENTS_ENDPOINT,
  GET_UUID_ENDPOINT,
  ADD_EMPLOYEE_ENDPOINT,
  DELETE_EMPLOYEE_ENDPOINT,
  ADD_TAG_ENDPOINT,
  DELETE_TAG_ENDPOINT,
  ADD_CUSTOM_CATEGORY_ENDPOINT,
  DELETE_CUSTOM_CATEGORY_ENDPOINT,
  FORM_SUBMISSION_ENDPOINT,

  // Collections (Database)
  EVENTS_COLLECTION,
  EMPLOYEES_COLLECTION,
  UTILS_COLLECTION,

  // HTTP Methods
  HTTP_POST,
  HTTP_GET,

  // External Links
  FACEBOOK_URL,
  INSTAGRAM_URL,
  WHATSAPP_GROUP_URL,
  SHAHAR_NUMBER,

  // Event Status
  EVENT_EXISTS,

  EVENTER,
  GO_OUT,
  ZYGO,
};

export default constants;
