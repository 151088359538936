import { useState, useEffect } from "react";
import { Button, Title, MultiSelect } from "@mantine/core";
import useApi from "../../hooks/useApi";
import constants from "../../constants/constants";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import "../../css/admin/CarouselEventManager.css";

interface CarouselEventManagerProps {
  eventsList: models.EventProps[];
}

export default function CarouselEventManager({
  eventsList,
}: CarouselEventManagerProps) {
  const [value, setValue] = useState<string[]>([]);
  const [dropDownOptions, setDropDownOptions] = useState<string[]>([]);
  const [initialValue, setInitialValue] = useState<string[]>([]);
  const { t } = useTranslation();
  const { callApi, loading } = useApi(
    `${constants.API_BASE_URL}${constants.UPDATE_CAROUSEL_EVENTS_ENDPOINT}`
  );

  useEffect(() => {
    setDropDownOptions(eventsList.map((event) => event.eventTitle));
    const initialCarouselValues = eventsList
      .filter((event) => event.isCarrousel)
      .map((event) => event.eventTitle);

    setValue(initialCarouselValues);
    setInitialValue(initialCarouselValues);
  }, [eventsList]);

  const isChanged = JSON.stringify(value) !== JSON.stringify(initialValue);

  const saveCarouselEvents = () => {
    const updatedEvents = eventsList
      .filter((event) =>
        value.some(
          (carouselEventTitle: string) =>
            carouselEventTitle === event.eventTitle
        )
      )
      .map((event) => ({
        ...event,
        isCarrousel: true,
      }));
    console.log("Updated events:", updatedEvents);
    callApi("POST", {
      carouselEvents: updatedEvents,
      uuid: uuidv4(),
    });
    setInitialValue(value);
    console.log("Saved to Firebase:", updatedEvents);
  };

  return (
    <div className="carousel-manager-container">
      <Title order={2}>{t("carouselTitle")}</Title>
      <div className="carousel-manager-form">
        <MultiSelect
          label="בחר את האירועים לקרוסלה"
          placeholder="בחר אירוע"
          data={dropDownOptions}
          value={value}
          onChange={setValue}
          clearable
          searchable
          nothingFoundMessage="אירוע לא נמצא"
          hidePickedOptions
        />
        <div className="save-carousel-button-container">
          <Button
            color="black"
            onClick={saveCarouselEvents}
            disabled={!isChanged || loading}
          >
            {t("saveCarousel")}
          </Button>
          {loading && <ClipLoader size={30} />}
        </div>
      </div>
    </div>
  );
}
